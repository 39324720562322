// This logic is duplicated between the App and the Gatsby static web site

const allCategories = [
  // Not really a category
  /* {
    key: 'featured',
    title: 'Featured',
    shortName: '',
    subtitle: '',
  }, */
  {
    key: 'fears',
    title: 'Conquering Fears',
    shortName: 'Fears',
    subtitle: 'Don\'t let fears hold you back',
    theme: { bg: 'hsl(38, 100%, 50%)', border: 'hsl(38, 100%, 40%)', chevron: '#fff', navBg: '#fff', nav: 'hsl(38, 100%, 50%)' },
  },
  {
    key: 'money',
    title: 'Attract Money',
    shortName: 'Money',
    subtitle: 'You can win the money game',
    theme: { bg: 'hsl(120, 76%, 44%)', border: 'hsl(120, 76%, 35%)', chevron: '#fff', navBg: '#fff', nav: 'hsl(120, 76%, 44%)' },
  },
  {
    key: 'sleep',
    title: 'Better Sleep',
    shortName: 'Sleep',
    subtitle: 'Enjoy restful revitalizing sleep every night',
    theme: { bg: 'hsl(205, 80%, 36%)', border: 'hsl(205, 80%, 26%)', chevron: '#fff', navBg: '#fff', nav: 'hsl(205, 80%, 36%)' },
  },
  // Was renamed from Stop Smoking:
  {
    key: 'smoke',
    title: 'Addiction',
    shortName: 'Addiction',
    subtitle: 'You are stronger than your addiction',
    theme: { bg: 'hsl(264, 35%, 60%)', border: 'hsl(264, 35%, 40%)', chevron: '#fff', navBg: '#fff', nav: 'hsl(264, 35%, 60%)' },
  },
  {
    key: 'stress',
    title: 'Stress Management',
    shortName: 'Stress',
    subtitle: 'Relieve stress and depression and feel consistently relaxed and happy',
    theme: { bg: 'hsl(55, 100%, 50%)', border: 'hsl(55, 70%, 48%)', chevron: 'hsl(55, 70%, 20%)', navBg: '#fff', nav: 'hsl(55, 70%, 20%)' },
  },
  {
    key: 'weight',
    title: 'Weight Control',
    shortName: 'Weight',
    subtitle: 'Win the mental game of weight control',
    theme: { bg: 'hsl(324, 80%, 56%)', border: 'hsl(324, 80%, 36%)', chevron: '#fff', navBg: '#fff', nav: 'hsl(324, 80%, 56%)' },
  },
  {
    key: 'love',
    title: 'Love & Relationships',
    shortName: 'Love',
    subtitle: 'Bring the best love into your life',
    theme: { bg: 'hsl(340, 67%, 62%)', border: 'hsl(340, 67%, 42%)', chevron: '#fff', navBg: '#fff', nav: 'hsl(340, 67%, 62%)' },
  },
  {
    key: 'parenting',
    title: 'Parenting & Kids',
    shortName: 'Parenting',
    subtitle: 'Creating a happy childhood for both parents and kids',
    theme: { bg: 'hsl(182, 100%, 82%)', border: 'hsl(182, 100%, 47%)', chevron: 'hsl(182, 100%, 27%)', navBg: '#fff', nav: 'hsl(182, 100%, 27%)' },
  },
  {
    key: 'growth',
    title: 'Personal Growth',
    shortName: 'Growth',
    subtitle: 'Train your mind for a happier, healthier, and more fulfilling life',
    theme: { bg: 'hsl(120, 76%, 87%)', border: 'hsl(120, 76%, 75%)', chevron: 'hsl(120, 83%, 30%)', navBg: '#fff', nav: 'hsl(120, 83%, 30%)' },
  },
  {
    key: 'studying',
    title: 'Studying & Performance',
    shortName: 'Studying',
    subtitle: 'Train your mind to improve your grades and athletic performance',
    theme: { bg: 'hsl(205, 100%, 90%)', border: 'hsl(205, 100%, 80%)', chevron: 'hsl(205, 80%, 36%)', navBg: '#fff', nav: 'hsl(205, 80%, 36%)' },
  },
  {
    key: 'physical',
    title: 'Physical Health',
    shortName: 'Physical',
    subtitle: 'Develop the right mindset for a healthier life',
    theme: { bg: 'hsl(324, 100%, 95%)', border: 'hsl(324, 100%, 90%)', chevron: 'hsl(324, 80%, 56%)', navBg: '#fff', nav: 'hsl(324, 80%, 56%)' },
  },
  {
    key: 'other',
    title: 'Other',
    shortName: 'Other',
    subtitle: 'Train your mind to overcome common obstacles',
    theme: { bg: 'hsl(0, 0%, 85%)', border: 'hsl(0, 0%, 75%)', chevron: 'hsl(0, 0%, 25%)', navBg: '#fff', nav: 'hsl(0, 0%, 25%)' },
  },
];

const allSeries = [
  {
    key: 'whileudrive',
    title: 'While-U-Drive',
    subtitle: 'We designed these programs specifically to be listened to while driving - with eyes open and while staying alert',
  },
  {
    key: 'forbusypeople',
    title: 'For Busy People',
    subtitle: 'These programs are designed to be listened to in short, bite-sized chunks - perfect for people without a lot of time to spend on meditation and mindfulness',
  },
  {
    key: 'lovetapes',
    title: 'Love Tapes',
    subtitle: 'These are the tied and true original programs Bob created - and have helped thousands of people worldwide better their lives',
  },
  {
    key: 'effectivemeditations',
    title: 'Effective Meditations',
    subtitle: 'These programs are perfect for those interested in exploring Mindfulness and Meditation - and applying those to improve specific aspects of their lives.',
  },
  {
    key: 'whileusleep',
    title: 'While-U-Sleep',
    subtitle: 'These programs are designed to be listened to while falling asleep and while sleeping - they have relaxing music or nature sounds, perfect for creating a restful and productive sleep environment',
  },
];

export const specialsTheme = { bg: 'hsl(356, 77%, 61%)', border: 'hsl(356, 77%, 41%)', chevron: 'hsl(356, 77%, 41%)', navBg: '#fff', nav: 'hsl(356, 77%, 41%)' };

export const whiteTheme = { bg: 'hsl(0, 0%, 97%)', border: 'hsl(0, 0%, 90%)', chevron: 'hsl(0, 0%, 25%)', navBg: '#fff', nav: 'hsl(0, 0%, 25%)' };

export function getCategory(categoryId) {
  return allCategories.find((c) => c.key === categoryId);
}

export function getCategoryName(program) {
  const { associations } = program;
  if (!associations) return 'Other';
  const { categories = {} } = associations;
  const categoryIds = Object.keys(categories);

  const firstCategory = allCategories.find((c) => categoryIds.includes(c.key));
  if (!firstCategory) return 'Other';
  return firstCategory.shortName;
}

export function getSeries(seriesId) {
  const series = allSeries.find((s) => s.key === seriesId);
  return series && {
    ...series,
    theme: whiteTheme,
  };
}

export function getAllCategoryIds() {
  return allCategories.map((c) => c.key);
}

export function getAllSeries() {
  return allSeries;
}
