// See also app iap-products.js
export default [
  { productId: 'bundle_111_101', wpProductId: 2750, title: 'Weight Loss Bundle', description: 'Powerful imagery and success statements for eliminating cravings and increasing energy. Makes it easy to shed unwanted pounds and inches forever!' },
  { productId: 'bundle_109_61', wpProductId: 2752, title: 'Self Esteem Bundle', description: 'Gain total self-acceptance, inner peace and happiness. Maximize your potential while learning to be your own best friend and a good friend to others.' },
  { productId: 'bundle_107_29', wpProductId: 2754, title: 'Creativity Bundle', description: 'Release your untapped mental abilities, think more clearly and creatively, and overcome mental blocks. You’ll learn to shift your creativity into high gear.' },
  { productId: 'bundle_108_66', wpProductId: 2756, title: 'Learning Bundle', description: 'Easily remember anything and regain your natural appetite for learning. Now you can learn any subject quickly, painlessly and enthusiastically.' },
  { productId: 'bundle_67_119', wpProductId: 2758, title: 'Moving Forward Bundle', description: 'Feel completely free of negative people or events of your past and get on with your life successfully. You’ll lift your spirits and enjoy a more positive out look on life.' },
  { productId: 'bundle_23_87', wpProductId: 2760, title: 'Charisma Bundle', description: 'Develop your ‘positive personal power’. Eliminate stress caused by negative people and learn to attract positive people into your business and social lives.' },
  { productId: 'bundle_110_9', wpProductId: 2762, title: 'Stop Smoking Bundle', description: 'Guaranteed to help you quit smoking easily and permanently. You’ll learn to break bad habits and achieve peak performance in everything you do.' },
  { productId: 'bundle_21_85', wpProductId: 2764, title: 'Fears and Anxiety Bundle', description: 'When you eliminate negativity and fears, your health and relationships improve and tier while life becomes more satisfying and enjoyable. You’ll enjoy a priceless inner peace.' },
  { productId: 'bundle_44_22', wpProductId: 2766, title: 'Productivity Bundle', description: 'Raise you energy level and feel great easily and naturally. You’ll work or play with more zest, vitality and enjoyment. And you’ll find the confidence and motivation to take on and complete any project.' },
  { productId: 'bundle_114_120', wpProductId: 2768, title: 'Visualization Bundle', description: 'Helps you become more resourceful, creative and confident. Learn how to visualize your goals and create new, positive realities very easily.' },
  { productId: 'bundle_118_56', wpProductId: 2770, title: 'Relaxation Bundle', description: 'Free yourself from harmful stress in any situation. Wonderful breathing techniques., soothing positive affirmations, and guided imagery help you enjoy complete relaxation.' },
  { productId: 'bundle_88_64', wpProductId: 2772, title: 'Positivity Bundle', description: 'Takes you beyond thinking to help you change your whole world for the better. Learn powerful techniques to put yourself in a great mood quickly and naturally.' },
  { productId: 'bundle_48_82', wpProductId: 2774, title: 'Peaceful Sleep Bundle', description: 'Guides you to a deep state of relaxation and enables you to enjoy healthy, restful sleep. Proven techniques for releasing negative thoughts and becoming a happier, worry-free person.' },
  { productId: 'bundle_52_15', wpProductId: 2776, title: 'Healing Bundle', description: 'Loaded with ideas, information and techniques to help you tap the natural healing power of your mind and spirit.' },
  { productId: 'bundle_78_57', wpProductId: 2778, title: 'Happiness Bundle', description: 'These special programs will help you make important, positive changes that will empower you to take control and make your life happier and more fulfilling.' },
  { productId: 'bundle_63_16', wpProductId: 2780, title: 'Business Success Bundle', description: 'Learn how to unleash your natural abilities for unlimited success in sales and all areas of business. You’ll internalize many important techniques and use them automatically and effortlessly.' },
  { productId: 'bundle_1_10', wpProductId: 2782, title: 'Confidence Bundle', description: 'Learn how to display confidence and become pleasantly assertive. You’ll quickly develop the elusive, undefined quality known as “charisma”.' },
  { productId: 'bundle_2_55', wpProductId: 2784, title: 'Achievement Bundle', description: 'These proven techniques and strategies will help you easily eliminate the roadblocks to prosperity and help you increase income, improve your social life, and gain many other valuable benefits.' },
  { productId: 'bundle_47_25', wpProductId: 2786, title: 'Decision Making Bundle', description: 'Both you and your lived ones will benefit as you learn the step-by-steo methods for making better personal and business decisions and eliminate codependent behavior.' },
  { productId: 'bundle_33_60', wpProductId: 2788, title: 'Dream Bundle', description: 'Make your dreams more positive and creative. You’ll remember your dreams and use them to get ideas and solve problems. Also learn to unblock your natural flow of good luck and success.' },
  { productId: 'bundle_124_71', wpProductId: 2790, title: 'Motivation Bundle', description: 'A comprehensive approach to motivating yourself and others. Awaken the dynamic qualities within you to become a winner in all areas of life.' },
];