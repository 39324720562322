import React from "react"
import sortBy from 'lodash/sortBy';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getCategory, getSeries } from '../logic/categories';
import AnimatedBrain from '../components/AnimatedBrain';

import bundles from '../logic/bundles';

// https://api.wiseguideapp.com/programs
import programs from '../data/programs.json';
import programsExtra from '../data/programsExtra.json';

// This logic is duplicated between the App and the Gatsby static web site

const getProgramUrl = (program) => `https://checkout.thewiseguideapp.com/product/${programsExtra[program.programId].slug}`;

const Program = ({ title, content, url, imgUrl }) => (
  <a href={url} style={{ display: 'block', margin: 'auto', color: 'inherit', width: '100%', maxWidth: 300, marginTop: 0, marginBottom: 50, marginLeft: 20, marginRight: 20 }}>
    <img src={imgUrl} alt="Cover" style={{ width: '100%', display: 'block', borderRadius: '2%', marginBottom: 20 }} />
    <div style={{ fontWeight: 'bold', marginBottom: 10 }}>{title}</div>
    <div style={{ height: 100, overflow: 'hidden' }}>{content}</div>
  </a>
);

export default ({ pageContext }) => {
  const { categoryId, seriesId, showBundles } = pageContext;
  
  let filteredPrograms;

  if (!showBundles) {
    // Taken from app
    function groupPrograms(type) {
      const grouped = {};
      programs.forEach((p) => {
        if (!(p.associations && p.associations[type])) return;
        Object.keys(p.associations[type]).forEach((key) => {
          if (!grouped[key]) grouped[key] = [];
          grouped[key].push(p);
        });
      });

      return grouped;
    }

    const programsByCategoryId = groupPrograms('categories');
    const programsBySeriesId = groupPrograms('series');

    filteredPrograms = programs;
    if (categoryId) filteredPrograms = programsByCategoryId[categoryId];
    else if (seriesId) filteredPrograms = programsBySeriesId[seriesId];

    filteredPrograms = sortBy(filteredPrograms, (p) => p.title);
  }

  function getTitle() {
    if (categoryId) return getCategory(categoryId).title;
    if (seriesId) return getSeries(seriesId).title;
    if (showBundles) return 'Special Bundles';
    return 'All Programs';
  }

  return (
    <Layout showCart>
      <SEO title="Programs" />
  
      <div>
        <div style={{ margin: '70px 50px' }}>
          <div style={{ margin: 'auto', maxWidth: 1000 }}>
            {(categoryId || seriesId) && <AnimatedBrain categoryId={categoryId} seriesId={seriesId} size={200} style={{ margin: '-60px auto -60px 0' }} />}

            <h2 style={{ marginTop: 50 }}>{getTitle()}</h2>
  
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: -20, marginRight: -20 }}>
              {showBundles
                ? bundles.map((bundle) => <Program key={bundle.productId} title={bundle.title} content={bundle.description} url={`http://checkout.thewiseguideapp.com/?p=${bundle.wpProductId}`} imgUrl={`https://wiseguide-library.s3.amazonaws.com/bundle-thumbnails/${bundle.productId}.jpg`} /> )
                : filteredPrograms.map((program) => <Program key={program.programId} title={program.title} content={program.content} url={getProgramUrl(program)} imgUrl={program.thumbnails[400].url} /> )
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );  
};
