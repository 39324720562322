import React from 'react';
import Lottie from 'react-lottie-player'

// NOTE: This code is duplicated between app and gatsby site

const fears = require('../lottie/brain-mountain.json');
const addiction = require('../lottie/brain-addiction.json');
const briefcase = require('../lottie/brain-briefcase-final.json');
const kid = require('../lottie/brain-kid-final.json');
const meditating = require('../lottie/brain-meditating-final.json');
const sleeping = require('../lottie/brain-sleeping.json');
const tracksuit = require('../lottie/brain-tracksuit-final.json');
const wateringSelf = require('../lottie/brain-watering-self.json');
const money = require('../lottie/brain-money-bags-mcgee.json');
const loveEyes = require('../lottie/brain-heart-eyes-fast-exag.json');
const headphones = require('../lottie/brain-headphones-oval.json');
const square = require('../lottie/brain-breathing-square-fast-exag.json');
const physical = require('../lottie/brain-barbells.json');

const inCar = require('../lottie/brain-in-car-final.json');
const inBed = require('../lottie/brain-in-bed-final.json');
const heartsOverHead = require('../lottie/brain-hearts-over-head.json');

// const baby = require('../lottie/brain-baby.json');
// const male = require('../lottie/brain-male-final.json');

const meditationConf = { lottie: meditating, margins: [-20, -2, -20, 0], size: 130 };
const headphonesConf = { lottie: headphones, margins: [0, 0, -7, 0], size: 125 };

const categoriesMap = {
  fears: { lottie: fears, margins: [0, 0, -6, 0], size: 130 },
  money: { lottie: money, margins: [0, -15, -46, 0], size: 170 },
  sleep: { lottie: sleeping, margins: [0, -4, -14, 0], size: 140 },
  smoke: { lottie: addiction, margins: [0, -2, -6, 0], size: 130 },
  stress: { lottie: meditating, margins: [0, -2, -5, 0], size: 130 },
  weight: { lottie: tracksuit, margins: [0, 8, 0, 0], size: 105 },
  love: { lottie: loveEyes, margins: [0, -2, -28, 0], size: 130 },
  parenting: { lottie: kid, margins: [0, 0, -25, 0], size: 120 },
  growth: { lottie: wateringSelf, margins: [0, 2, -6, 0], size: 130 },
  studying: headphonesConf,
  physical: { lottie: physical, margins: [0, -13, -7, 0], size: 140 },
  other: { lottie: square, margins: [0, -1, -30, 0], size: 125 },
};

const seriesMap = {
  whileudrive: { lottie: inCar, margins: [-10, -2, -10, 0], size: 130 },
  forbusypeople: { lottie: briefcase, margins: [-10, 0, -10, 0], size: 130 },
  lovetapes: { lottie: heartsOverHead, margins: [-10, 0, -10, 0], size: 120 },
  effectivemeditations: meditationConf,
  whileusleep: { lottie: inBed, margins: [-10, 0, -10, 0], size: 120 },
};

export function getAnimation(categoryId, seriesId) {
  return categoryId ? categoriesMap[categoryId] : seriesMap[seriesId];
}

const baseSize = 100;

const AnimatedBrain = ({ categoryId, seriesId, size: inSize = baseSize, style }) => {
  const mapped = getAnimation(categoryId, seriesId);
  const { lottie, margins, size, speed } = mapped;

  const scaledSize = (size * inSize) / baseSize;
  const factor = (inSize / baseSize);

  return (
    <Lottie
      animationData={lottie}
      loop
      play
      speed={speed || 1}
      style={{
        // backgroundColor: 'red',
        width: scaledSize,
        height: scaledSize,
        marginTop: margins[0] * factor,
        marginRight: margins[1] * factor,
        marginBottom: margins[2] * factor,
        marginLeft: margins[3] * factor,
        ...style,
      }}
    />
  );
};

export default AnimatedBrain;
